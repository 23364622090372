export const recordCreate = 'ui/record/create'
export const recordEditLock = 'ui/record/edit/lock'
export const recordLoad = 'ui/record/load'
export const recordLoadError = 'ui/record/load/error'
export const recordDelete = 'ui/record/delete'
export const recordUuidPreviewUpdate = 'ui/record/preview/update'
export const recordCheckedOut = 'ui/record/checkedout'
export const nodesUpdate = 'ui/record/node/update'
export const nodeDelete = 'ui/record/node/delete'
export const validationsUpdate = 'ui/record/validation/update'
